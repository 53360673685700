import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible/index.js'
import 'vant/lib/index.css' // 全局引入样式

Vue.config.productionTip = false

// import veGameSDK from '@volcengine/vegame'
// Vue.prototype.veGameSDK = veGameSDK
import * as uni from '@/utils/uni.webview.1.5.2.js'
document.addEventListener('UniAppJSBridgeReady', function () {
  Vue.prototype.myUni = uni
})

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
